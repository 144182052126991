.body {
    background-color: grey;
}

.loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    left: 0;
    line-height: 200px;
    margin-top: -100px;
    position: absolute;
    text-align: center;
    top: 40%;
    width: 100%;
}

.validation-message {
    color:red;
    max-width: 265px;
}