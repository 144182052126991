body {
    margin: 0;
    height: 100% !important;
    font-family: 'Noto Sans JP', sans-serif;
}

code {
    font-family: 'Noto Sans JP', sans-serif;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #495c67 !important;
    color: #fff;
}

.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
    background-color: #495c67 !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
    background-color: #495c67 !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.icon-big {
    height: 35px;
    width: 35px;
}

.icon-small {
    height: 15px;
    width: 15px;
}